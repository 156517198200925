@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;200;300;400;500;600;700;800;900&display=swap');
@font-face {
  font-family: 'SSTArabic';
  src: url('./fonts/SSTArabic-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SSTArabic';
  src: url('./fonts/SSTArabic-Light.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SSTArabic';
  src: url('./fonts/SSTArabic-Medium.ttf') format('truetype');
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'SSTArabic';
  src: url('./fonts/SSTArabic-Roman.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

html[lang='ar'] * {
  font-family: 'SSTArabic', sans-serif;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
body::-webkit-scrollbar {
  display: none;
}

.bg-radial-gradient {
  /* background: linear-gradient(180deg, rgba(245, 245, 247, 0.8) 0%, rgba(250, 250, 251, 0) 100%) , linear-gradient(180deg, rgba(0, 13, 43, 0.5) 0%, rgba(0, 0, 0, 0) 100%); */
  background: linear-gradient(180deg, #000d2b 0%, rgba(0, 0, 0, 0) 100%),
    linear-gradient(
      180deg,
      #fff 0%,
      hsl(0deg 0% 93.85% / 0%) 48%,
      hsl(0deg 0% 0% / 94%) 100%
    ),
    linear-gradient(
      180deg,
      rgb(191 191 191 / 80%) 0%,
      rgb(255 255 255 / 0%) 100%
    );
}

* {
  font-family: 'roboto', sans-serif;
}

.ant-checkbox-checked .ant-checkbox-inner::after {
  width: 4.714286px !important;
  height: 8.142857px !important;
  transform: rotate(45deg) scale(1) translate(-54%, -59%) !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: #1c4585; /* Change to your preferred color */
  border-color: #1c4585; /* Optional: change border color to match */
}

.ant-radio-checked .ant-radio-inner {
  border-color: #1c4585 !important;
  background-color: #1c4585 !important;
}

@layer utilities {
  @variants responsive {
    /* Hide scrollbar for Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    /* Hide scrollbar for IE, Edge and Firefox */
    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
  }
  .svg-icon-hover:hover {
    filter: invert(20%) sepia(40%) saturate(2454%) hue-rotate(197deg)
      brightness(95%) contrast(92%);
  }

  .socialMediaIconHover {
    filter: brightness(200%);
  }

  .contactUsSocialMediaIconHover {
    filter: invert(38%) sepia(99%) saturate(660%) hue-rotate(166deg)
      brightness(84%) contrast(95%);
  }

  .headerIconsHover {
    filter: brightness(60%) sepia(100%) hue-rotate(90deg);
  }

  .ContextCardShadow {
    box-shadow: 0px 0px 8px 0px #00000080;
  }

  .BackgroundWithoutBlend {
    background: white;
    background-blend-mode: normal;
  }

  .blending-shadow {
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 31.25%,
      rgba(255, 255, 255, 0) 72.4%,
      #ffffff 100%
    );
  }
  .Dreams-card-shadow {
    background: linear-gradient(
      360deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0) 31.25%,
      rgba(255, 255, 255, 0) 72.4%,
      #ffffff 100%
    );
  }
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fadeInInactive {
    from {
      opacity: 0;
    }
    to {
      opacity: 0.7;
    }
  }

  .fade-in-active {
    animation: fadeIn 0.5s ease-in-out;
  }

  .fade-in-inActive {
    animation: fadeInInactive 0.5s ease-in-out;
  }

  @keyframes fadeOutActive {
    from {
      opacity: 1;
    }
    to {
      opacity: 0;
    }
  }

  @keyframes fadeOutInactive {
    from {
      opacity: 0.7;
    }
    to {
      opacity: 0;
    }
  }

  .fade-out-active {
    animation: fadeOutActive 0.5s ease-in-out;
  }

  .fade-out-inActive {
    animation: fadeOutInactive 0.5s ease-in-out;
  }

  @keyframes rotateAnimation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(180deg);
    }
  }

  @keyframes rotateBackAnimation {
    from {
      transform: rotate(180deg);
    }
    to {
      transform: rotate(0deg);
    }
  }

  .rotate {
    animation: rotateAnimation 0.5s ease-in-out forwards;
  }

  .rotate-back {
    animation: rotateBackAnimation 0.5s ease-in-out forwards;
  }

  .rtl {
    direction: rtl !important;
  }

  .ltr {
    direction: ltr !important;
  }

  .slide-out-left {
    animation: slideLeft 0.5s ease forwards;
  }

  .slide-out-right {
    animation: slideRight 0.5s ease forwards;
  }

  .slide-in-right {
    animation: slideFromRight 0.5s ease forwards;
  }

  .slide-in-left {
    animation: slideFromLeft 0.5s ease forwards;
  }

  @keyframes slideLeft {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(-100%);
    }
  }

  @keyframes slideRight {
    from {
      transform: translateX(0);
    }
    to {
      transform: translateX(100%);
    }
  }

  @keyframes slideFromRight {
    from {
      transform: translateX(100%);
    }
    to {
      transform: translateX(0);
    }
  }

  @keyframes slideFromLeft {
    from {
      transform: translateX(-100%);
    }
    to {
      transform: translateX(0);
    }
  }

  .arabicLanguage {
    font-family: 'SSTArabic', sans-serif !important;
  }

  .awardsIconFilter {
    filter: invert(62%) sepia(98%) saturate(352%) hue-rotate(349deg)
      brightness(153%) contrast(97%);
  }
  .bottomNavBarIconsFilter {
    filter: invert(62%) sepia(98%) saturate(352%) hue-rotate(203deg)
      brightness(153%) contrast(200%);
  }

  ol > li::marker {
    font-weight: 300;
  }
}
